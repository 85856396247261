import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { SlugifyService } from '../../services/slugify/slugify.service';
import { AlertController } from '@ionic/angular';
import { WindowService } from '../../services/window/window.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({
	providedIn: 'root'
})
/**
 * Service for interacting with the Tomtom API.
 */
export class AlgoliaService {
	awsAPIKey: string = '';
	private apiCallCount = 0;
	private resetInterval = 3 * 60 * 1000;
	private maxApiCalls = 50;
	intervalId: any;
	filterCountries = [
		'FRA',
		'BEL',
		'AND',
		'LUX',
		'CHE',
		'NCL',
		'PYF',
		'MYT',
		'SPM',
		'BLM',
		'MAF',
		'WLF',
		'REU',
		'MTQ',
		'GLP',
		'GUY',
		'ITA',
		'ESP',
		'MCO',
		'GUF',
	];

	constructor(
		private backApiService: BackApiService,
		private alertController: AlertController,
		private windowService: WindowService,
		private slugifyService: SlugifyService,
		private settingsService: SettingsService) {
		if (environment.isPwa) {
			this.awsAPIKey = environment.awsLocationApiKeyWeb;
		} else {
			this.awsAPIKey = environment.awsLocationApiKeyApp;
		}
		this.settingsService.getLocationKey().pipe(filter(apiKey => apiKey !== null && apiKey !== '')
		).subscribe((apiKey) => {
			this.awsAPIKey = apiKey;
		});
		if (this.windowService.isPlatformBrowser()) {
			this.intervalId = setInterval(() => {
				this.apiCallCount = 0;
			}, this.resetInterval);
		}
	}

	private canMakeApiCall(): boolean {
		if (this.windowService.isPlatformServer() || this.apiCallCount < this.maxApiCalls) {
			this.apiCallCount++;
			return true;
		} else {
			this.showAlert('Vous avez atteint la limite de requêtes autorisées. Veuillez réessayer dans quelques minutes', 'Limite de requêtes atteinte');
			return false;
		}
	}

	ngOnDestroy() {
		if (this.intervalId && this.windowService.isPlatformBrowser()) {
			clearInterval(this.intervalId);
		}
	}


	/**
	* Effectue une requête à AWS Location Service pour obtenir une adresse complète à partir d'une requête.
	* @param {string} search - Les termes de recherche à interroger.
	*/
	getAdress(search: string, addDomsToms: boolean = false) {
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Nombre maximal de requêtes atteint. Veuillez réessayer plus tard.'));
		}

		const filterCountries = this.filterCountries;

		const requestBody = {
			Text: search,
			Language: 'fr',
			MaxResults: 6,
			biasPosition: [46.6475, 2.6195], // Centre de la France
			FilterCategories: ['AddressType', 'StreetType'],
			FilterCountries: filterCountries,
		};

		const region = environment.awsLocationRegion;
		const indexName = environment.awsPlaceIndexName;
		const endpoint = `https://places.geo.${region}.amazonaws.com`;
		const path = `/places/v0/indexes/${indexName}/search/suggestions`;
		const url = endpoint + path + '?key=' + this.awsAPIKey;

		return this.backApiService.postData(url, requestBody, false, false).pipe(
			map((res: any) => {
				let formattedRes = [];
				if (res && res.Results && res.Results.length > 0) {
					formattedRes = res.Results;
				} else {
					formattedRes = [{ Text: 'Aucune adresse trouvée' }];
				}
				if (addDomsToms) {
					this.addDomsToms(formattedRes, search);
				}
				return formattedRes;
			}),
			catchError((e) => {
				console.log('AWS getAdress() a retourné une erreur');
				if (e.status === 403) {
					this.settingsService.initLocationKey().subscribe();
				}
				throw e;
			})
		);
	}


	/**
	 * Calculates the Levenshtein distance between two strings.
	 * The Levenshtein distance is a measure of the difference between two strings,
	 * defined as the minimum number of single-character edits (insertions, deletions, or substitutions)
	 * required to change one string into the other.
	 *
	 * @param a - The first string.
	 * @param b - The second string.
	 * @returns The Levenshtein distance between the two strings.
	 */
	levenshtein(a: string, b: string): number {
		const matrix: number[][] = [];
		b = b.slice(0, a.length);

		for (let i = 0; i <= b.length; i++) {
			matrix[i] = [i];
		}

		for (let j = 0; j <= a.length; j++) {
			matrix[0][j] = j;
		}

		for (let i = 1; i <= b.length; i++) {
			for (let j = 1; j <= a.length; j++) {
				if (b.charAt(i - 1) === a.charAt(j - 1)) {
					matrix[i][j] = matrix[i - 1][j - 1];
				} else {
					matrix[i][j] = Math.min(
						matrix[i - 1][j - 1] + 1,
						Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
					);
				}
			}
		}

		return matrix[b.length][a.length];
	}

	/**
	 * Check if the search term matches a DOM-TOM
	 * @param searchTherm 
	 * @returns 
	 */
	searchThermMatchDomTom(searchTherm: string): boolean {
		console.log("ALGOLIASERVICE searchThermMatchDomTom()");
		const domtoms = [
			'Réunion',
			'Guadeloupe',
			'Martinique',
			'Mayotte',
			'Guyane',
			'Saint-Pierre-et-Miquelon',
			'St-Pierre-et-Miquelon',
			'Saint-Barthélemy',
			'St-Barthélemy',
			'Saint-Martin',
			'St-Martin',
			'Wallis-et-Futuna',
			'Nouvelle-Calédonie',
			'Polynésie'
		];

		const slugifiedSearchTerm = this.slugifyService.slugify(decodeURIComponent(searchTherm));

		return domtoms.some(domtom => {
			const slugifiedDomTom = this.slugifyService.slugify(domtom);
			console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedDomTom = " + slugifiedDomTom);
			console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm = " + slugifiedSearchTerm);
			console.log(searchTherm);
			if (slugifiedSearchTerm.length > 3) {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm.length > 3");
				const distance = this.levenshtein(slugifiedSearchTerm, slugifiedDomTom);
				return distance <= 1; // Autoriser une faute de frappe
			} else if (slugifiedSearchTerm.length > 6) {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm.length > 6");
				const distance = this.levenshtein(slugifiedSearchTerm, slugifiedDomTom);
				return distance <= 2; // Autoriser 2 fautes de frappe
			} else {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() else");
				return slugifiedSearchTerm.startsWith(slugifiedDomTom);
			}
		});
	}

	/**
	 * Add DOM-TOMs to the list of results
	 * @param formatedRes 
	 * @param search
	 * @returns
	 * */
	addDomsToms(formatedRes: any, searchTherm: string = '') {
		console.log("ALGOLIASERVICE addDomsToms()");
		console.log(formatedRes);
		let domtomFound: boolean = false;
		formatedRes.forEach((element: any) => {
			if (
				element?.Text.includes('Réunion') || // La Réunion
				element?.Text.includes('Guadeloupe') || // Guadeloupe
				element?.Text.includes('Martinique') || // Martinique
				element?.Text.includes('Mayotte') || // Mayotte
				element?.Text.includes('Guyane') || // Guyane française
				element?.Text.includes('Miquelon') || // Saint-Pierre-et-Miquelon
				element?.Text.includes('Barthélemy') || // Saint-Barthélemy
				element?.Text.includes('-Martin') || // Saint-Martin (partie française)
				element?.Text.includes('Wallis') || // Wallis-et-Futuna
				element?.Text.includes('Calédonie') || // Nouvelle-Calédonie
				element?.Text.includes('Polynésie')    // Polynésie française
			) {
				domtomFound = true;
			}
		});
		if (domtomFound || this.searchThermMatchDomTom(searchTherm)) {
			formatedRes.unshift({
				"Text": "France d'outre-mer, Tous les DOM-TOMs",
			});
			console.log("ALGOLIASERVICE addDomsToms() end");
			console.log(formatedRes);
		}
	}

	formatAwsToAlgolia(place: any) {
		const formattedResult: any = {};
		if (place.Text) {
			formattedResult.Text = place.Text;
			formattedResult.localisation = place.Text;
		} if (place.Label) {
			formattedResult.Text = place.Label;
			formattedResult.localisation = place.Label;
		}
		if (place.Geometry && place.Geometry.Point) {
			formattedResult['_geoloc'] = {};
			formattedResult['_geoloc'].lat = place.Geometry.Point[1];
			formattedResult['_geoloc'].lng = place.Geometry.Point[0];
			formattedResult.latitude = place.Geometry.Point[1];
			formattedResult.longitude = place.Geometry.Point[0];
		}
		if (place.Country) {
			formattedResult.country = place.Country;
			if (formattedResult.country == 'FRA') {
				formattedResult.country = 'France';
			}
			formattedResult.countryCode = place.Country.substring(0, 2);
		}
		if (place.Region) {
			formattedResult.locality = place.Region;
		}
		if (place.SubRegion) {
			formattedResult.departement = place.SubRegion;
		}
		if (place.PostalCode) {
			formattedResult.zipcode = place.PostalCode;
			formattedResult.postcode = place.PostalCode;
		}
		if (place.Municipality) {
			formattedResult.city = place.Municipality;
			formattedResult.citySlug = this.slugifyService.slugify(place.Municipality.toLowerCase());
		}
		if (place.Street) {
			formattedResult.street = place.Street;
		}
		if (place.AddressNumber) {
			formattedResult.number = place.AddressNumber;
		}
		if (place.Street && place.AddressNumber && place.PostalCode && place.Municipality) {
			formattedResult.formatedAddress = `${place.AddressNumber} ${place.Street}, ${place.PostalCode} ${place.Municipality}`;
		} else {
			formattedResult.formatedAddress = place.Label;
		}
		return formattedResult;
	}


	getCity(search: string, addDomsToms: boolean = false) {
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}

		const filterCountries = this.filterCountries;

		const requestBody = {
			Text: search,
			Language: 'fr',
			MaxResults: 6,
			biasPosition: [46.6475, 2.6195], // Centre de la France
			FilterCategories: ['MunicipalityType', 'NeighborhoodType', 'PostalCodeType'],
			FilterCountries: filterCountries,
		};

		const region = environment.awsLocationRegion;
		const indexName = environment.awsPlaceIndexName;
		const endpoint = `https://places.geo.${region}.amazonaws.com`;
		const path = `/places/v0/indexes/${indexName}/search/suggestions`;
		const url = endpoint + path + '?key=' + this.awsAPIKey;

		return this.backApiService.postData(url, requestBody, false, false).pipe(
			map((res: any) => {
				let formattedRes = [];
				if (res && res.Results && res.Results.length > 0) {
					formattedRes = res.Results;
				} else {
					formattedRes = [{ Text: 'Aucune adresse trouvée' }];
				}
				if (addDomsToms) {
					this.addDomsToms(formattedRes, search);
				}
				return formattedRes;
			}),
			catchError((e) => {
				console.log('AWS getCity() a retourné une erreur');
				if (e.status === 403) {
					this.settingsService.initLocationKey().subscribe();
				}
				throw e;
			})
		);
	}

	/* Use AWS SearchPlaceIndexForPosition
	* @param {string} coordinates - The coordinates to search for.
	* @returns {Observable<any>} An observable of the search results.
	*/
	getZipCodeFromCoordinates(coordinates: any) {
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}

		const region = environment.awsLocationRegion;
		const indexName = environment.awsPlaceIndexName;
		const endpoint = `https://places.geo.${region}.amazonaws.com`;
		const path = `/places/v0/indexes/${indexName}/search/position`;
		const url = endpoint + path + '?key=' + this.awsAPIKey;

		const requestBody = {
			Position: coordinates,
			Language: 'fr',
			MaxResults: 1,
		};

		return this.backApiService.postData(url, requestBody, false, false).pipe(
			map((res: any) => {
				console.log("ALGOLIASERVICE getZipCodeFromCoordinates() request to AWS =");
				console.log(res);
				let cp = '';
				if (res.Results[0]?.Place?.PostalCode) {
					cp = res.Results[0]?.Place?.PostalCode;
				}
				return cp;
			}), catchError(e => {
				console.log("AWS getZipCodeFromCoordinates() returned error");
				throw e;
			}));
	}


	/**
	 * Get the current position lat lng of the user
	 * @returns {Observable<any>} an observable of the current position
	 */
	getCurrentLatLng(): Observable<any> {
		let latLng;
		let lat: string;
		let lng: string;
		let observableFromPromise;
		return observableFromPromise =
			from(
				Geolocation.getCurrentPosition().then((resGeo) => {
					lat = resGeo.coords.latitude.toString();
					lng = resGeo.coords.longitude.toString();
					console.log('ALGOLIASERVICE geolocalise()');
					console.log(resGeo);
					latLng = { lat: lat, lng: lng };
					return latLng;
				}, e => {
					console.log('Error getting location', e);
					throw e
				}));
	}


	getCoordonateFromPlaceId(placeId: string = '') {
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}

		const region = environment.awsLocationRegion;
		const indexName = environment.awsPlaceIndexName;
		const endpoint = `https://places.geo.${region}.amazonaws.com`;
		const path = `/places/v0/indexes/${indexName}/places/` + placeId;
		const url = endpoint + path + '?key=' + this.awsAPIKey;

		return this.backApiService.getData(url, false, false).pipe(
			map((res: any) => {
				console.log("ALGOLIASERVICE getCoordonateFromPlaceId() request to AWS =");
				console.log(res);
				let formatedRes = this.formatAwsToAlgolia(res.Place);
				console.log("ALGOLIASERVICE getCoordonateFromPlaceId() request to AWS formated  =");
				console.log(formatedRes);
				return formatedRes;
			}),
			catchError((e) => {
				console.log('ALGOLIASERVICE getCoordonateFromPlaceId() a retourné une erreur');
				throw e;
			})
		);
	}



	/**
	* Display Error
	* @param {string} msg Error message
	*/
	showAlert(msg: string = "", title: string = "Erreur") {
		let alert = this.alertController.create({
			message: msg,
			header: title,
			buttons: ['OK']
		});
		alert.then(alert => alert.present());
	}
}



