import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { SettingsService } from '../../services/settings/settings.service';
import { AuthService } from '../auth/auth.service';
import { WindowService } from '../window/window.service';
import { ErrorsService } from '../errors/errors.service';


@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  candidate: any = {};
  user: any = null;
  userUid: string | null = null;
  userRole: string | null = null;
  userRoleObs: BehaviorSubject<any> = new BehaviorSubject(null);
  userUidObs: BehaviorSubject<any> = new BehaviorSubject(null);
  isNew: boolean = false;
  premium: boolean = false;
  premiumObs: BehaviorSubject<any> = new BehaviorSubject(false);
  userEmail: string | null = "";
  userEmailObs: BehaviorSubject<any> = new BehaviorSubject(null);
  userSubs: Subscription | null = null;
  logOutObs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private backApiService: BackApiService,
    private storage: LocalStorageService,
    private alertController: AlertController,
    private settingsService: SettingsService,
    private windowService: WindowService,
    private errorsService: ErrorsService,
    private auth: AuthService) {
    console.log('PROFIL cst()');
    this.restoreStoredcandidate();
  }

  /**
   * Initializes the profile service.
   * 
   * This method performs the following tasks:
   * 1. Checks if the application is a PWA (Progressive Web App) and if so, calls the `checkIfLoggedFromSsoThenInitProfil()` method.
   * 2. Initializes the user UID.
   * 3. Initializes the user role.
   * 4. Initializes the user email.
   * 5. Subscribes to the `userUid` observable and performs the following actions if a `userUid` is available:
   *    - Subscribes to the `isAuthenticatedObs()` observable and performs the following actions if the `authState` is `false` and the application is running in a browser environment:
   *      - Clears the user data after logout by calling the `clearUserAfterLogout()` method.
   */
  init() {
    console.log("PROFILSERVICE init()");
    if (environment.isPwa) {
      this.checkIfLoggedFromSsoThenInitProfil();
    }
    this.initUserUid();
    this.initUserRole();
    this.initUserEmail();
    this.getuserUidObs().subscribe(userUid => {
      if (userUid) {
        this.auth.isAuthenticatedObs().subscribe(authState => {
          console.log("profilService authState");
          console.log(authState);
          console.log(this.userRole);
          console.log(this.windowService.isPlatformBrowser());
          if (authState === false && this.windowService.isPlatformBrowser()) {
            console.log("profilService authState before timmeout");
            console.log("profilService authState after timmeout");
            console.log(this.auth.isAuthenticated());
            this.clearUserAfterLogout();
            console.log("profilService authState after timmeout clearUserAfterLogout() :");
          }
        });
      }
    });
  }

  /**
   * Clears the user data after logout.
   * 
   * This method clears the user data and resets the necessary properties to their default values.
   * It also notifies the logOutObs subject to indicate that the user has logged out.
   * 
   * @remarks
   * This method should be called when the user logs out of the application.
   */
  clearUserAfterLogout() {
    console.log("PROFILSERVICE clearUserAfterLogout() start");
    if (!this.user && !this.userRole) {
      return;
    }
    this.logOutObs.next(true);
    this.candidate = {};
    this.userUid = null;
    this.isNew = false;
    this.userUidObs.next(null);
    this.userEmail = null;
    this.user = null;
    this.premiumObs.next(null);
    this.premium = false;
    this.userRole = null;
    this.userRoleObs.next(null);
    this.userEmailObs.next(null);
    this.settingsService.clearSubscribtions();
    console.log("PROFILSERVICE clearUserAfterLogout() end");
  }

  getLogOutObs() {
    return this.logOutObs;
  }

  getIsPremium() {
    return this.premium;
  }

  getIsPremiumObs() {
    return this.premiumObs.asObservable();
  }

  /**
   * Checks the user's role until payment is made.
   * 
   * This method iterates 20 times with a delay of 2 seconds between each iteration.
   * If the user is not a premium user, it initializes and saves the user's profile.
   * 
   * @remarks
   * This method is typically used to continuously check the user's role until the payment is successfully made. // Should be replaced when Subscription/premium on Mercure is implemented
   * 
   * @example
   * ```typescript
   * checkRoleUntilPaiement();
   * ```
   */
  checkRoleUntilPaiement() {
    for (let i = 0; i < 20; i++) {
      setTimeout(() => {
        if (!this.getIsPremium()) {
          this.initAndSaveUserProfil().subscribe(() => {
            console.log("checkRoleUntilPaiement PAYEMENTSUCCES initAndSaveUserProfil " + i + " " + this.getIsPremium());
          });
        }
      }, 2000 * i);
    }
  }

  /**
   * Initializes and saves the user profile.
   * 
   * This method retrieves user information from the API, updates the user's profile, and saves it to storage.
   * It also updates the user's role and premium status based on the retrieved information.
   * 
   * @returns A promise that resolves to the retrieved user information.
   * @throws If an error occurs during the API call.
   */
  initAndSaveUserProfil() {
    console.log('PROFILSERVICE - initAndSaveUserProfil() start');
    return this.getUserInfosFromApi().pipe(take(1),
      map((res: any) => {
        console.log('PROFILSERVICE - initAndSaveUserProfil() getUserInfosFromApi()');
        console.log(res);
        if (res.uid !== this.userUid) {
          this.userUid = res.uid;
          this.userUidObs.next(this.userUid);
        }
        let roles = res.roles;
        console.log('PROFILSERVICE initAndSaveUserProfil() - userUidObs.next()');
        this.storage.set('userUid', this.userUid);
        this.premiumObs.next(false);
        this.premium = false;
        console.log('PROFILSERVICE initAndSaveUserProfil() - user is NOT premium');
        console.log(roles);
        for (let i = 0; i < roles.length; i++) {
          if (roles[i] == 'ROLE_CANDIDATE' || roles[i] == 'ROLE_RECRUITER' && (roles[i] != this.userRole || this.userEmail != res.login)) {
            this.userRole = roles[i];
            this.userRoleObs.next(this.userRole);
            this.settingsService.setUserRole(this.userRole);
            this.storage.set('userRole', this.userRole);
            console.log('PROFILSERVICE initAndSaveUserProfil() - user role set to ');
            console.log(roles[i]);
          } else if (!this.userRole) {
            this.settingsService.setUserRole(null);
          }
          if (roles[i] == 'ROLE_RECRUITER_PREMIUM') {
            this.premiumObs.next(true);
            this.premium = true;
            console.log('PROFILSERVICE initAndSaveUserProfil() - user is premium');
          }
        }
        this.userEmail = res.login;
        this.storage.set('userEmail', this.userEmail);
        return res;
      }),
      catchError(e => {
        throw e;
      }));
  }


  getIsNew() {
    return this.isNew;
  }

  setIsNew(isNew: boolean) {
    this.isNew = isNew;
  }

  /**
   * Initializes the user UID.
   * Retrieves the user UID from storage and updates the `userUid` property.
   * If the retrieved user UID is different from the current `userUid`, it updates the `userUid` property,
   * emits the updated `userUid` through the `userUidObs` observable,
   * and logs the updated `userUid` to the console.
   * If no user UID is stored in the storage, it logs a message indicating that no user UID is stored.
   */
  initUserUid() {
    console.log('PROFILSERVICE initUserUid()');
    this.storage.get('userUid').then((userUid: any) => {
      if (userUid && userUid != this.userUid) {
        this.userUid = userUid;
        this.userUidObs.next(this.userUid);
        console.log('PROFIL SERVICE restoreStoredPersonProfil() userUid = ');
        console.log(this.userUid);
      }
      else {
        console.log('PROFIL SERVICE restoreStoredPersonProfil() no userUid stored');
      }
    });
  }

  /**
   * Initializes the user role.
   * Retrieves the user role from storage and updates the current user role if it is different.
   * Notifies observers of the updated user role.
   * Sets the user role in the settings service.
   * Logs the user role to the console.
   * If no user role is stored, logs a message indicating that no user role is stored.
   */
  initUserRole() {
    this.storage.get('userRole').then((userRole: any) => {
      if (userRole && userRole != this.userRole) {
        this.userRole = userRole;
        this.userRoleObs.next(this.userRole);
        this.settingsService.setUserRole(this.userRole);
        console.log('PROFIL SERVICE initUserRole() userRole = ');
        console.log(this.userRole);
      }
      else {
        console.log('PROFIL SERVICE initUserRole() no userRole stored');

      }
    });
  }

  /**
   * Initializes the user email.
   * Retrieves the user email from storage and updates the userEmail property.
   * If the retrieved email is different from the current userEmail, it updates the userEmailObs subject.
   * Logs the retrieved email to the console.
   * If no email is stored, it logs a message indicating that there is no stored email.
   */
  initUserEmail() {
    this.storage.get('userEmail').then((userEmail: any) => {
      if (userEmail && userEmail != this.userEmail) {
        this.userEmail = userEmail;
        this.userEmailObs.next(this.userEmail);
        console.log('PROFIL SERVICE initUserEmail() userEmail = ');
        console.log(this.userEmail);
      }
      else {
        console.log('PROFIL SERVICE initUserEmail() no initUserEmail stored');
      }
    });
  }

  /**
  * Edit users infos like email or mdp
  * @param {credentials} credentials Takes email and password and old password
  * @returns AuthServer response
  */
  editCredential(credentials: any) {
    let url = environment.registerPath + '/' + this.userUid;
    console.log('PROFILSERVICE editCredential :');
    console.log(credentials);
    return this.backApiService.putData(url, credentials, true, false).pipe(
      tap((res: any) => {
        console.log('PROFILSERVICE editCredential');
        console.log(res);
      }),
      catchError(e => {
        console.log(e);
        this.errorsService.displayError(e);
        throw e;
      }));
  }


  /**
   * Retrieves user information from the API.
   * 
   * @returns {Observable<any>} An observable that emits the user information.
   * @throws {any} Throws an error if unable to retrieve the user information.
   */
  getUserInfosFromApi() {
    console.log('PROFILSERVICE getUserInfosFromApi() start');
    console.log(environment.account)
    let url = environment.account;

    return this.backApiService.getData(`${url}`, true).pipe(
      map((res: any) => {
        console.log('PROFILSERVICE getUserInfosFromApi() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("PROFILSERVICE getUserUid() res returned error");
        this.showAlert("Impossible de récuperer votre identifiant utilisateur. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }


  getuserUid() {
    return this.userUid;
  }

  getuserUidObs() {
    return this.userUidObs.asObservable();
  }

  getuserRole() {
    return this.userRole;
  }

  getuserRoleObs() {
    return this.userRoleObs.asObservable();
  }


  getUser() {
    return this.user;
  }

  getuserEmail() {
    return this.userEmail;
  }

  getuserEmailObs() {
    return this.userEmailObs.asObservable();
  }

  /**
   * Retrieves the job title based on the job id.
   * 
   * @returns The job title corresponding to the job number. Returns null if the job number is not found.
   */
  getJobFromNumber() {
    switch (this.candidate.job) {
      case "20":
        return "Pharmacien (H/F)";
        break;
      case "21":
        return "Préparateur en Pharmacie (H/F)";
        break;
      case "22":
        return "Etudiant en Pharmacie (H/F)";
        break;
      case "47":
        return "Etudiant en Pharmacie 6e année validée (H/F)";
        break;
      case "28":
        return "Conseiller Dermo Cosmetique - Esthéticienne (H/F)";
        break;
      case "40":
        return "Délégué pharmaceutique (H/F)";
        break;
      case "41":
        return "Interne en Pharmacie (H/F)";
        break;
      case "46":
        return "Orthopédiste-Orthésiste (H/F)";
        break;
      case "23":
        return "Apprenti Préparateur (H/F)";
        break;
      case "30":
        return "Diététicien (H/F)";
        break;
      case "29":
        return "Rayonniste - Preparateur de commande - Aide préparateur (H/F)";
        break;
      case "31":
        return "Administratif - Secrétaire (H/F)";
        break;
      case "39":
        return "Assistant Technique Respiratoire (H/F)";
        break;
      case "42":
        return "Technicien d'analyses biomédicales (H/F)";
        break;
      case "43":
        return "Opticien (H/F)";
        break;
      default:
        return null;
        break;
    }

  }

  /**
   * Return stored candidate data
   * @return {object} candidate  - full profile  
   */
  restoreStoredcandidate() {
    this.storage.get('personProfil').then((candidate: any) => {
      if (candidate) {
        this.candidate = candidate;
        console.log('PROFIL SERVICE restoreStoredPersonProfil() candidate = ');
        console.log(this.candidate);
      }
      else {
        console.log('PROFIL SERVICE restoreStoredPersonProfil() no personalProfile stored');
      }
    }, (error: any) => {
      console.log('restoreStoredcandidate() error');
      console.log(error);
    });
  }

  /**
   * Stores the candidate elements.
   * 
   * @param elements - The elements to be stored.
   */
  storecandidateElements(elements: any) {
    console.log('PROFILSERVICE storecandidatelements() element =');
    console.log(elements);
    Object.keys(elements).forEach(key => {
      console.log(key);
    });
    if (elements['job']) {
      this.candidate.job = elements['job'];
    }
    this.storage.set('personProfil', this.candidate);
  }

  getcandidate() {
    return this.candidate;
  }




  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  /* PWA */
  checkIfLoggedFromSsoThenInitProfil() {
    this.settingsService.checkForSsoLogin().subscribe(loggedFromSso => {
      if (loggedFromSso) {
        this.initAndSaveUserProfil().subscribe((res: any) => {
        },
          error => {
            console.log('PROFILSERVICE checkIfLoggedFromSsoThenInitProfil() initAndSaveUserProfil error');
            console.log(error);
          });
      }
    });
  }

}
