import { Injectable } from '@angular/core';
import { MaintenanceModalPage } from '../../pages/maintenance-modal/maintenance-modal.page';
import { ModalController } from '@ionic/angular';
import { SettingsService } from '../settings/settings.service';
import { ProfilService } from '../profil/profil.service';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InfosLiveService {
  isMobile: boolean | null = null;
  userRoleObs = new BehaviorSubject<string | null>(null);
  userRole: string | null = null;
  infosViewedIds: any = [];

  constructor(
    private modalController: ModalController,
    private settingsService: SettingsService,
    private storage: LocalStorageService,
    private profilService: ProfilService
  ) {

    this.settingsService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.storage.get('infosViewedIds').then((infosViewedIds: any) => {
      console.log('INFOSLIVE_S constructor() infosViewedIds');
      console.log(infosViewedIds);
      if (infosViewedIds?.length > 0) {
        this.infosViewedIds = infosViewedIds;
      }
      let roleSubs = this.profilService.getuserRoleObs().subscribe(role => {
        console.log('APPCOMPONENT initializeApp() role =');
        console.log(role);
        if (role && this.userRole != role && (role == 'ROLE_CANDIDATE' || role == 'ROLE_RECRUITER')) {
          console.log('INFOSLIVE_S constructor() roleSubs');
          console.log(role);
          this.userRole = role;
          this.userRoleObs.next(role);
        }
      });
    });
  }

  subscribeToInfosLive() {
    this.userRoleObs.pipe(filter(val => val != null),
      distinctUntilChanged()).subscribe(role => {
        console.log('INFOSLIVE_S subscribeToInfosLive()');
        let today = new Date();
        let august2024 = new Date('2024-08-12');
        if (today < august2024 && this.infosViewedIds.indexOf('1') == -1 && role == 'ROLE_CANDIDATE') {
          console.log('INFOSLIVE_S subscribeToInfosLive() openMaintenanceModal mobile');
          this.openInfosModal(undefined, 'info', false, { title: 'Nous améliorons notre système de zones de recherche !', subtitle: null, detail: "Grâce à vos retours, l’option « Mobile si logé » est désormais plus précise. Vous pouvez indiquer pour chaque zone géographique si elle vous intéresse en général ou seulement si un logement est fourni. L’option initiale « Mobile si logé sur toute la France métropolitaine et l’outre-mer » reste disponible.", image: 'https://storage.clubofficine.fr/api/storage/statics/b1f4a220-6907-4680-ada6-69af31786990', pageImgHeight: '200', link: 'https://www.youtube.com/watch?v=GwrsVATPXCY', openPageUrl: 'candidat/c-profil/searchAddresses', skipButtonName: 'Ignorer', openPageUrlButtonName: 'Mettre à jour', id: '1' });
        }
      });
  }




  async openInfosModal(detail: any, type: string | null = null, canDismiss: boolean = false, info: any = null) {
    console.log('INFOSLIVE_S openMaintenanceModal()');
    let breakpoints: Array<number> | undefined;
    let initialBreakpoint: number | undefined;
    if (this.isMobile) {
      breakpoints = [0, 0.35, 0.5, 0.8, 1];
      initialBreakpoint = 0.8;
    }
    let dismissOptions: any = (data?: any, role?: string) => {
      return new Promise<boolean>(resolve => resolve(role === "close"))
    };
    if (canDismiss === true) {
      dismissOptions = true;
    }
    const modal = await this.modalController.create({
      component: MaintenanceModalPage,
      canDismiss: dismissOptions,
      backdropDismiss: true,
      componentProps: {
        // "detail": detail,
        "type": type,
        "info": info,
        "initialBreakpoint": initialBreakpoint,
      }
    });

    modal.onDidDismiss().then((dataReturned: any) => {
      console.log('INFOSLIVE_S openMaintenanceModal() onDidDismiss');
      console.log(dataReturned);
      if (dataReturned?.data?.infosViewedId) {
        console.log('INFOSLIVE_S openMaintenanceModal() onDidDismiss res retourned');
        this.infosViewedIds.push(dataReturned.data.infosViewedId);
        this.storage.set('infosViewedIds', this.infosViewedIds);
      }
    });
    return await modal.present();
  }


}

