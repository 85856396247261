
/**
 * This service is responsible for managing user's associate wishes.
 * It provides methods to fetch, update, and manipulate data related to the associate wishes.
 * The service interacts with a backend service or API to retrieve and persist data, ensuring data consistency across sessions.
 * It maintains an observable of the user's associate wishes, allowing other parts of the application to react to changes in the data.
 * Additionally, it provides methods to format and aggregate wishes for easier data handling.
 * Error handling is built into data fetching and updating methods to ensure smooth user experience even when issues occur.
 */

import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../profil/profil.service';

@Injectable({
  providedIn: 'root'
})


export class AssociateService {
  myAssociateWishes: any = null;
  myAssociateWishesObs: BehaviorSubject<any> = new BehaviorSubject(null);
  myAssociateWishesSubscribtion: Subscription | null = null;
  userRole: string | null = null;
  userUid: string | null = null;

  constructor(
    private alertController: AlertController,
    private backApiService: BackApiService,
    private profilService: ProfilService) {
    console.log('ASSOCIATE_S constructor()');
    this.profilService.getuserRoleObs().subscribe(role => {
      this.userRole = role;
    });
    this.profilService.getuserUidObs().subscribe(userUid => {
      this.userUid = userUid;
    });
  }


  /**
   * Initializes or updates the data associated with the user's associate wishes.
   * Unsubscribes from any existing subscriptions to avoid memory leaks.
   * Fetches data from a backend service, handling any errors that may occur during the process.
   * @returns {void}
   */
  initMyAssociateWishes() {
    console.log('ASSOCIATE_S initMyAssociateWishes()');
    console.log(`${environment.associateWishes}`);
    if (this.myAssociateWishesSubscribtion) {
      this.myAssociateWishesSubscribtion.unsubscribe();
    }
    this.myAssociateWishesSubscribtion = this.backApiService.getData(`${environment.associateWishes}`, true).subscribe((res: any) => {
      console.log('ASSOCIATE_S initMyAssociateWishes() res retourned =');
      console.log(res);
      if (res?.["hydra:member"]?.[0]) {
        this.reformatWishes(res["hydra:member"][0]);
        this.myAssociateWishesObs.next(res["hydra:member"][0]);
      } else {
        this.myAssociateWishesObs.next('empty');
        console.log("ASSOCIATE_S initMyAssociateWishes() empty")
      }
    }, error => {
      console.log("ASSOCIATE_S initMyAssociateWishes() res returned error");
      this.showAlert("Impossible de récuperer les information sur votre recherche d'associés. Verifiez votre connexion ou réessayez plus tard");
    });
  }

  /**
   * Returns an observable stream of the user's associate wishes.
   * Useful for subscribing to and reacting to changes in the associate wishes.
   * @returns {Observable} Observable stream of the user's associate wishes.
   */
  getMyAssociateWishesObs() {
    return this.myAssociateWishesObs.asObservable();
  }

  /**
 * TO BE REMOVED IN DECEMBER 2023 WHEN ALL WISHES WILL BE REFORMATED IN BACK AND MOBILES APPS UPDATED
 * @param wishes 
 * @returns 
 * 
 */
  reformatWishes(wishes: any) {
    console.log("ASSOCIATE_S reformatWishes() wishes =");
    console.log(wishes);
    console.log(this.userRole);
    if (!wishes.projectTypes?.[0]) {
      return;
    }
    wishes.projectTypes.forEach((res: any) => {
      if (res.name == 'practicing_partner') {
        res.name = 'practicing_partner_search';
      } else if (res.name == 'investment_partner' && this.userRole == 'ROLE_CANDIDATE') {
        res.name = 'investment_partner_search';
      } else if (res.name == 'solo') {
        res.name = 'solo_installation_search';
      } else if (res.name == 'investment_partner' && this.userRole == 'ROLE_RECRUITER') {
        res.name = 'pharmacy_invest';
      }
    });
  }

  /** ""
   * Updates the current associate wishes observable with a new value.
   * @param {object} wishes The new wishes to update the observable with.
   * @returns {void}
   */
  setMyAssociateWishesObs(wishes: any) {
    this.myAssociateWishesObs.next(wishes);
  }

  /**
   * Retrieves a specific associate wish by its unique identifier (uid).
   * Handles any errors that occur during data retrieval.
   * @param {string} uid The unique identifier of the associate wish to retrieve. 
   * @returns {Observable} Observable stream of the requested associate wish.
   */
  getAssociateWishe(uid: string | null = null) {
    console.log('ASSOCIATE_S getAssociateWishe()');
    console.log(uid);
    return this.backApiService.getData(`${environment.associateWishes}/${uid}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getAssociateWishe() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getAssociateWishe() res returned error");
        this.showAlert("Impossible de récuperer les informations sur la recherche d'associés. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }


  /**
   * Updates a specific associate wish by its unique identifier (uid).
   * Handles any errors that occur during the update process.
   * @param {string} uid The unique identifier of the associate wish to update.
   * @param {any} wishe The updated data for the associate wish.
   * @returns {Observable} Observable stream of the updated associate wish.
   */
  putAssociateWishe(uid: string, wishe: any) {
    console.log('ASSOCIATE_S putAssociateWishes()');
    console.log(uid);
    if (wishe.hasProject == 'true') {
      wishe.hasProject = true;
    } else if (wishe.hasProject == 'false') {
      wishe.hasProject = false;
    }
    return this.backApiService.putData(`${environment.associateWishes}/${uid}`, wishe, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S putAssociateWishes() res retourned =');
        console.log(res);
        this.setMyAssociateWishesObs(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S putAssociateWishes() res returned error");
        this.showAlert("Impossible de modifier les les informations sur votre recherche d'associés. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
   * Creates a new associate wish by making a POST request to the backend service.
   * Handles any errors that occur during the creation process.
   * @param {any} wishe The new associate wish to be created.
   * @returns {Observable} Observable stream of the newly created associate wish.
   */
  postAssociateWishe(wishe: any) {
    console.log('ASSOCIATE_S postAssociateWishe()');
    if (wishe.hasProject == 'true') {
      wishe.hasProject = true;
    } else if (wishe.hasProject == 'false') {
      wishe.hasProject = false;
    }
    return this.backApiService.postData(`${environment.associateWishes}`, wishe, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S postAssociateWishe() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S postAssociateWishe() res returned error");
        this.showAlert("Impossible de sauvegarder les les informations sur votre recherche d'associés. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
   * Get all proposes of a user
   * @returns {Observable} Observable stream of the user proposes
   */
  getProposes() {
    console.log('ASSOCIATE_S getProposes()');
    return this.backApiService.getData(`${environment.associatePropose}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getProposes() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProposes() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les proposition. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  /**
   * Get a propose by its unique identifier (uid)
   * @param {string} uid The unique identifier of the propose to retrieve.
   * @returns {Observable} Observable stream of the requested propose.
   */
  getPropose(uid: string | null = null) {
    console.log('ASSOCIATE_S getPropose()');
    console.log(uid);
    return this.backApiService.getData(`${environment.associatePropose}/${uid}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getPropose() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getPropose() res returned error");
        this.showAlert("Impossible de récuperer les informations sur la proposition. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  /**
   * Creates a new propose by making a POST request to the backend service.
   * Handles any errors that occur during the creation process.
   * @param {any} propose The new propose to be created.
   * @returns {Observable} Observable stream of the newly created propose.
   */
  putPropose(uid: string, propose: any) {
    console.log('ASSOCIATE_S putPropose()');
    console.log(propose);
    return this.backApiService.putData(`${environment.associatePropose}/${uid}`, propose, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S putPropose() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S putPropose() res returned error");
        this.showAlert("Impossible de modifier les informations sur la proposition. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

}